import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.env,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  enableTracing: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/admin\.erpclass\.com\.br/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

@Injectable({ providedIn: 'root' })
export class ErrorHandlerUser implements ErrorHandler {
  public handleError(error: any): void {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    }
    console.error(error);
  }
}
